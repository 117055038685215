import React from "react";
import { useEffect} from "react";
import './LoginForm.css'

function LoginForm({handleSubmit}){

    
    return(
        
        <form className="loginForm" onSubmit={handleSubmit}>
        {/* <h3>OBS: Inloggning endast för administratörer</h3> */}
        <label for="email">E-post</label>
        <br/>
        <input type="text" id="email" name="email"/>
        <br/><br/>
        <label for="password">Lösenord</label>
        <br/>
        <input type="password" id="password" name="password"/>
        <br/><br/>
        <button className="btn" type="submit">Logga in</button>
        </form>
    )
}

export default LoginForm;