import React from "react";
import './Regler.css'
import ImgReg from './Exempel_registrering.jpg'

function Regler() {
  return (
    <div className="content">
      <img class="img100" src="https://images.pexels.com/photos/209954/pexels-photo-209954.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="image"></img>
      <div className="text">
        <h3>

          <ul>
            <li><h1>Vad göra?</h1></li>
            <li>Ni kommer individuellt samla steg. Personen som har samlat flest steg mellan de utsatta datumen vinner. </li>
            <li><h1>Tid?</h1></li>
            <li> 15/5 - 28/5, Totalt 14 dagar</li>
            <li><h1>Hur redovisa?</h1></li>
            <li>Skicka resultatet till Lina Rasmusson, Tove Nytell eller idrott@uppsalasystemvetare.se. En screenshot/skärmdump med steg och datumen krävs. Exempel på hur detta kan se ut finns längst ned på denna sida. Giltig stegräknare, android, iOS-stegräknare är tillåtet.</li>
            <br />
            <li>OBS: Alla steg MÅSTE registreras innan utsatta datum (se "Viktiga datum"). Ofullständig inrapportering leder till diskvalificering</li>
          </ul>


          <ul>
            <li><h1>Priser</h1></li>
            <li>Vinnaren och den som kommer på andra plats får såklart ett pris! Utöver ettan & tvåan kommer vi även lotta ut ett pris till en random deltagare!</li>
            <br></br>
            <li>1:a 250 kr voucher på Foodora!</li>
            <li>2:a 150 kr voucher på Foodora!</li>
            <li>Lucky looser: 100 kr voucher på Foodora!</li>
          </ul>


          <ul>
            <h1><div className="red">Viktiga datum</div></h1>
            <li>Måndag 15/5 00:00, tävlingen startar</li>
            <li>Söndag 21/5 senast 23:59: redovisa steg för första veckan (Datum & steg)</li>
            {/* <li>Lördag 20/5, systemvetarprommis + överraskning :)</li> */}
            <li>Söndag 28/5 senast 23:59: redovisa steg för andra veckan (Datum & steg)</li>
          </ul>
          <br />

        </h3>
      </div>
      <img className="center" src={require('./Exempel_registrering.jpg')} alt="Registrering" width="300px"></img>
      <h3>Exempel på registrering av resultat</h3>







    </div>
  );
}

export default Regler;