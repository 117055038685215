import React from 'react';
import {addUser} from "./Firebase";
import {useState, useEffect} from "react";
import './AddUserForm.css'

function AddUserForm(){
    const [name, setName] = useState();

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.target);
        if(name == undefined)
        {
            alert("Vänligen fyll i alla fält");
            return;
        }
        addUser(name);
        // console.log(name);
    };
    
    return(
        <form id="FormAddUser" onSubmit={handleSubmit}>
            <h3>Lägg till deltagare <br></br> i databasen</h3>
        <label htmlFor="name">Namn:</label>
        <input type="text" id="name" name="name" onChange={(e) => setName(e.target.value)}/>
        <button className="btn" type="submit">Lägg till</button>
        </form>
    )
}

export default AddUserForm;