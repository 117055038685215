import React from "react";
import "./Information.css";

function Information() {
  return (
    <div>
      {/*<img class="img100" src="https://images.pexels.com/photos/2402777/pexels-photo-2402777.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="image"/>*/}
      <img class="img100" src="https://images.pexels.com/photos/287240/pexels-photo-287240.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="image"/>
      <h1>Hälsofördelar med promenader och gång</h1>
      <p>
Libero enim sed faucibus turpis. Tincidunt dui ut ornare lectus sit amet est. Porttitor lacus luctus accumsan tortor posuere ac ut. Elementum nibh tellus molestie nunc non blandit massa enim. Odio ut sem nulla pharetra. Diam maecenas ultricies mi eget mauris pharetra et ultrices. Arcu cursus vitae congue mauris rhoncus aenean vel elit scelerisque. Dolor morbi non arcu risus quis. Nibh tortor id aliquet lectus proin nibh nisl. A diam sollicitudin tempor id eu nisl nunc. Amet nisl purus in mollis nunc sed id semper. Viverra ipsum nunc aliquet bibendum enim facilisis. Ut eu sem integer vitae justo eget.
      </p>
      <p>
      Nisl condimentum id venenatis a. Mauris sit amet massa vitae. Nec ultrices dui sapien eget mi proin sed. Pulvinar sapien et ligula ullamcorper. Leo urna molestie at elementum eu facilisis. Massa eget egestas purus viverra accumsan in nisl nisi scelerisque. Purus viverra accumsan in nisl nisi. Bibendum enim facilisis gravida neque convallis a cras semper auctor. Risus commodo viverra maecenas accumsan. Condimentum id venenatis a condimentum vitae sapien pellentesque habitant. Et magnis dis parturient montes nascetur. Vel facilisis volutpat est velit egestas dui id. Tellus mauris a diam maecenas sed enim ut sem. Neque convallis a cras semper auctor neque. Lorem ipsum dolor sit amet consectetur adipiscing. Eros donec ac odio tempor. Nunc consequat interdum varius sit amet mattis vulputate. Massa massa ultricies mi quis. Condimentum id venenatis a condimentum vitae. Ultricies leo integer malesuada nunc vel risus commodo.
      </p>
    </div>
  );
}

export default Information;