import React, {useState, useEffect} from "react";
import './DropdownItem.css'
import { Link } from "react-router-dom";

function DropdownItem(props) {
    
    return (
    <div className="dropdownItem" onClick={props.onClick}>
        <Link class="link" to={props.href}>
            <div className="buttons">
                <h2>{props.text}</h2>
            </div>
        </Link>
    </div>

    );
}

export default DropdownItem;