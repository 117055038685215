import React from "react";
import {getUsers, deleteUser} from "./Firebase";
import {useState, useEffect} from "react";
import './ResultForm.css'

function DeleteUserForm(){
    const [users, setUsers] = useState();
    const [user, setUser] = useState();

  useEffect(() => {
    async function fetchUsers() {
      const result = await getUsers();
      setUsers(result);
    }
    fetchUsers();
  }, []);

  const isUser = (name) => {
    return users.find((user) => user.name == name) != undefined;
  }
  
  const getUser = (name) => {
    return users.find((user) => user.name == name);
    }
  
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.target);
    if(user == undefined || !isUser(user))
    {
      alert("Vänligen fyll i alla fält");
      return;
    }
    var userId = getUser(user).id;
    deleteUser(userId)
    event.target.reset();
  };
  
  const userSelect = () => 
  {
    users.sort((a,b) => a.name > b.name ? 1 : -1)
    var options = users.map((user,index) => (
      <option key={index}>{user.name}</option>
    ))
    //order options by name

    var opt = <option value="">---</option>
    options = [opt, ...options];
    return options;
  }
    
    return(
        <form className="resultForm" onSubmit={handleSubmit}>
        <h3>Radera Deltagare</h3>

        <label htmlFor="user">Namn: </label>
        <input list="user" placeholder="Ange namn" onChange={(e) => setUser(e.target.value)}></input>
        <datalist id="user" name="user" placeholder="Ange deltagare">
          { users == undefined ?  <option value="">---</option>
          :
          userSelect()
          }
        </datalist>
        <input className="btn" type={"submit"} value={"Submit"} />
      </form>
    )
}

export default DeleteUserForm;