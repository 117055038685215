import React, { useState, useEffect } from 'react'
import { getUserRole, addResult, deleteResult, updateResult } from '../components/Firebase';

function Entry({ entry, index, user, year }) {

    const [isAdmin, setIsAdmin] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const checkAdmin = async () => {
          const role = await getUserRole();
          if (role == "admin") {
            setIsAdmin(true);
            setIsLoading(false);
          }
          else {
            setIsAdmin(false);
            setIsLoading(false);
          }
        }
        checkAdmin();
      }, [user]);

    {/* <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", gap: "20px"}}>
            <h1>{index}</h1>
            <h1>{entry.name}</h1>
            <h1>{entry.steps}</h1>
        </div>*/}


        const [week1, setWeek1] = useState();
        const [week2, setWeek2] = useState();
        const handleSubmit = async (event) => {
            event.preventDefault();
            const data = new FormData(event.target);
            if(week1 == undefined && week2 == undefined)
            {
                alert("Vänligen fyll i alla fält");
                return;
            }
            else if(week1 == undefined)
            {
                await deleteResult(entry.userID, year, 2)
                addResult(entry.userID, week2, year, 2)
            }
            else if(week2 == undefined)
            {
                await deleteResult(entry.userID, year, 1)
                addResult(entry.userID, week1, year, 1)
            }
    }

    const handleDelete = (event) => {
        event.preventDefault();
        deleteResult(entry.userID, year, 1)
        deleteResult(entry.userID, year, 2)
    }

    return (

        <tr>
            <td className="rank">{index}</td>
            <td>{entry.name}</td>
            <td>{entry.stepsWeek1}</td>
            <td>{entry.stepsWeek2}</td>
            <td>{entry.totalSteps}</td>
            {isAdmin ? 
            
            <div>
                <form onSubmit={handleSubmit}>
                    <label htmlFor="week1">Vecka 1:</label>
                    <input type="text" id="name" week1="week1" onChange={(e) => setWeek1(e.target.value)}/>
                    <div></div>
                    <label htmlFor="week2">Vecka 2:</label>
                    <input type="text" id="name" week2="week2" onChange={(e) => setWeek2(e.target.value)}/>
                    <div></div>
                    <button className="btn" type="submit">Edit</button>
                </form>


{
                <td>
                    <form onSubmit={handleDelete}>
                    <input type="hidden" id="name" week1="week1" onChange={(e) => setWeek1(e.target.value)}/>
                    <div></div>
                    <input type="hidden" id="name" week2="week2" onChange={(e) => setWeek2(e.target.value)}/>
                    <button className="btn" type="submit">Delete</button>
                </form>
                </td>
                } 
            </div>
            : <></>}
        </tr>
        

    )
}

export default Entry;