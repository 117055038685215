import React, { useState, useEffect } from 'react';
import "./Home.css";



function Home() {

    return (
        <div>
            {/*<img class="img100" src="https://www.inntravel.co.uk/getmedia/58ed438e-30ba-44e8-a501-ce4f82438551/walking-gentle-to-moderate?width=1920&height=900&ext=.jpg" alt="image"/>*/}
            <img class="img100" id="imgBanner" src="https://images.pexels.com/photos/869258/pexels-photo-869258.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="image" />
            <div className="text">
                <h1>Uppsala Systemvetares Stegtävling 2023!</h1>

                <h3>Välkommen till Uppsala Systemvetares stegtävling, en rolig
                    och utmanande tävling som får dig att röra på dig och
                    samtidigt träffa massa trevliga studentkamrater!

                    <br />
                    <br />

                    Stegtävlingen är öppen för alla medlemmar och syftar till
                    att främja hälsa och välbefinnande genom fysisk aktivitet.
                    Tävlingen är individuell och pågår under 2 veckor, där målet är att samla så många steg som möjligt.

                    <br />
                    <br />
                    Lycka till!
                </h3>
            </div>
        </div>
    );
}

export default Home;