import React, { useState, useEffect } from "react";
import { getUsers, addResult, setUserRole, getUserRole, loginUser, getAllUsers } from "./Firebase";
import ResultForm from "./ResultForm";
import AddUserForm from "./AddUserForm";
import DeleteUserForm from "./DeleteUserForm";
import UpdateUserForm from "./UpdateUserForm";
import UserManagementForm from "./UserManagementForm";
import { useAuth } from "./UserContext";
import { Navigate } from "react-router-dom";
import './Admin.css'

function Admin({user}) {
  const [isLoading, setIsLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  //const {user} = useAuth();
  

  useEffect(() => {
    const checkAdmin = async () => {
      console.log(user);
      const role = await getUserRole();
      if (role == "admin") {
        console.log("admin")
        setIsAdmin(true);
        setIsLoading(false);
        console.log(isAdmin);
      }
      else {
        console.log("not admin")
        setIsAdmin(false);
        setIsLoading(false);
      }
    }
    checkAdmin();
  }, [user]);

  return (
    <div>
      {
        isLoading ? <div>Loading...</div> :
          !isAdmin ? <Navigate replace to="/" /> :
            <div className="adminPage">
              <ResultForm/>
              <AddUserForm/>
              {/* <UserManagementForm/> */}
              <DeleteUserForm/>
              <UpdateUserForm/>
            </div>
      }

    </div>
  );
}

export default Admin;