import React, {useState, useEffect} from "react";
import { getUserRole } from "./Firebase";
import { Link } from "react-router-dom";
import { useAuth } from "./UserContext";
import "./Navbar.css";



function Navbar(props) {

    const [open, setOpen] = useState(false);

    
    return (
        <nav>
            <div className="logo-wrapper" >
                <a className="image-link" href="/">
                    <img id="logo_full" src="https://uppsalasystemvetare.se/wp-content/themes/uppsalasystemvetare/img/systemvetare-logo-white-2.png" alt="logo"/>
                </a>
            </div>

            <div className="button-wrapper">
                
                <Link class="link" to="/leaderboard">
                    <div className="buttons">
                        <h2>Leaderboard</h2>
                    </div>
                </Link>
                <Link class="link" to="/regler">
                    <div className="buttons">
                        <h2>Regler</h2>
                    </div>
                </Link>
                {/* <Link class="link" to="/information">
                    <div className="buttons">
                        <h2>Information</h2>
                    </div>
                </Link> */}
                {
                    props.isAdmin ?
                    <Link class="link" to="/admin">
                        <div className="buttons">
                            <h2>Admin</h2>

                        </div>
                    </Link>
                    :
                    <></>
                }
                {
                    props.isAdmin ?
                    <Link class="link" to="/login">
                        <div className="buttons">
                            <h2>Logout</h2>
                        </div>
                    </Link>
                    :
                    <></>
                    // <Link class="link" to="/login">
                    //     <div className="buttons">
                    //         <h2>Login</h2>
                    //     </div>
                    // </Link>
                }
                
                <Link class="link" to="/contact">
                    <div className="buttons">
                        <h2>Kontakt</h2>
                    </div>
                </Link>
            </div>

            {/*Detta är hamburgermenyn som dyker upp i "mobilläge" */}
            <div className="navbar-nav"> {props.children}</div>    
        </nav>
    );
}

export default Navbar;
