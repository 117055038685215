import React from 'react';

function NoPage() {
    return (
        <div>
            <h1>404</h1>
            <h2>Not found</h2>
        </div>
    )
}
export default NoPage