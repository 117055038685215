import React from 'react'
import Entry from './Entry'
import './Table.css'

function Table({ entries, user, year }) {

    entries.sort((a, b) => (a.totalSteps < b.totalSteps) ? 1 : -1)

    const entryList = entries.map((entry, index) => <Entry entry={entry} index={index+1 + "."} user={user} year={year}/>)

{/*<div id="leaderBoardTable" style={{}}>
            <h1>Namn</h1>
            <h1>Totala steg</h1>
            {entryList}
        </div>*/}
    return (
    
        <table>
            <thead>
                <tr>
                    <th className="rank">Rank</th>
                    <th>Namn</th>
                    <th>Steg vecka 1</th>
                    <th>Steg vecka 2</th>
                    <th>Totala steg</th>
                    </tr>
                </thead>
                <tbody>
                    {entryList}
                </tbody>
            </table>
           
    
        );
}

export default Table;