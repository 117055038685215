import React, {useState, useEffect} from "react";
import './NavItem.css';

function NavItem(props, {isAdmin}) 
{

    return (
        
            <div className="nav-item">
                <div>
                {props.children}
                </div>
                <a className="icon-button" ref={props.menuRef} onClick={props.onClick} >
                    {props.icon}
                </a>           
            </div>
    );
}

export default NavItem;