import React, {useState} from "react";
import LoginForm from "./LoginForm";
import {loginUser,logoutUser} from "./Firebase";
import { useAuth } from './UserContext';


function Login() {
    const [isLoading, setIsLoading] = useState(false);
    const { user } = useAuth();

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.target);
        const email = data.get("email");
        const password = data.get("password");
        setIsLoading(true);
        loginUser(email, password).then(() => {
            setIsLoading(false);
        });

    };

    const handleLogout = () => {
        setIsLoading(true);
        logoutUser();
    }



    return (

        <div>
            {user ? 
            <button onClick={handleLogout}>Logga ut</button>:
            <div><LoginForm handleSubmit={handleSubmit}></LoginForm></div>
            }
        </div>
    );
}

export default Login;