import React, {useState, useEffect} from "react";
import './DropdownMenu.css'

function DropdownMenu(props) {
    
    return (
       <div className="dropdownMenu">
        
            {props.children}

       </div>
    );
}

export default DropdownMenu;