import React from "react";
import "./Information.css";

function Contact() {
  return (
    <div>
      <h1>Kontaktuppgifter</h1>


	  <h3>För frågor rörande tävlingen</h3>
      <p>
	  Idrott@uppsalasystemvetare.se
      </p>

	  <h3>För frågor rörande hemsidan och IT</h3>
      <p>
	  it@uppsalasystemvetare.se
      </p>
    </div>
  );
}

export default Contact;
