import React, {useState,useEffect} from 'react';
import Table from "./Table";
import Entry from "./Entry";
import "./Leaderboard.css";
import {getResults} from './Firebase';
 

const currYear = new Date().getFullYear();
const startYear = 2021

function Leaderboard(user) {

    const [resultList, setResultList] = useState();
    const [year, setYear] = useState(currYear);

    useEffect(() => {
        async function fetchResults () {
            const result = await getResults(year);
            console.log(result);
            setResultList(result);
        }
        fetchResults();
    },[year]);

    const nextYear = () =>{
        setYear(year+1);
    }
    const prevYear = () =>{
        setYear(year-1);
    }

    return (
        <div className="leaderboard">
            <div style={{display: 'flex', alignItems: 'center', flexDirection: 'row', gap: '20px'}}>

            {
                year > startYear ? 
                <button className="btn" onClick={prevYear}>← {year-1}</button>
                :
                <></>
            }
            
            <h1>{year}</h1>
            {   year >= currYear ?
                <div/>
                :
                <button className="btn" onClick={nextYear}>{year+1} →</button>
            }
            </div>
            <h1>Leaderboard</h1>
            {/*<Podium/>*/}
            {   resultList == undefined?
                <p>Loading</p>
                :
                resultList.length > 0 ? <Table entries={resultList} user={user} year={year}/> : <h3>Resultat är ännu inte registrerat</h3>
            }
        </div>
    );
}

export default Leaderboard;