import React from "react";
import {getUsers, addResult} from "./Firebase";
import {useState, useEffect} from "react";
import './ResultForm.css'

const currYear = new Date().getFullYear();
//list of all years from currYear to 2020
const years = Array.from(new Array(5), (val, index) => currYear-index);
const weeks = [1,2];

function ResultForm(){
    const [users, setUsers] = useState();
  const [year, setYear] = useState(currYear);
  const [steps, setSteps] = useState();
  const [user, setUser] = useState();
  const [week, setWeek] = useState(1);

  useEffect(() => {
    async function fetchUsers() {
      const result = await getUsers();
      setUsers(result);
    }
    fetchUsers();
  }, []);

  const isUser = (name) => {
    return users.find((user) => user.name == name) != undefined;
  }
  
  const getUser = (name) => {
    return users.find((user) => user.name == name);
    }
  
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.target);
    if(steps == undefined || user == undefined || year == undefined || week == undefined || !isUser(user))
    {
      alert("Vänligen fyll i alla fält");
      return;
    }
    var userId = getUser(user).id;
    addResult(userId,steps,year,week);
    event.target.reset();
  };
  
  const userSelect = () => 
  {
    users.sort((a,b) => a.name > b.name ? 1 : -1)
    var options = users.map((user,index) => (
      <option key={index}>{user.name}</option>
    ))
    //order options by name

    var opt = <option value="">---</option>
    options = [opt, ...options];
    return options;
  }
    
    return(
        <form className="resultForm" onSubmit={handleSubmit}>
        <h3>Lägg in resultat</h3>

        
        <label htmlFor="user">Namn: </label>
        <input list="user" placeholder="Ange namn" onChange={(e) => setUser(e.target.value)}></input> 
        <datalist id="user" name="user" placeholder="Ange deltagare" onChange={(e) => setUser(e.target.value)}>
          { users == undefined ?  <option value="">---</option>
          :
          userSelect()
          }
        </datalist>
        
        <label htmlFor="steps">Antal steg:</label>
        <input type="number" id="steps" name="steps" placeholder="Ange antal steg" onChange={(e) => setSteps(e.target.value)}/>
        
        <div id="year_and_week">
          <div class="flex_column">
            <label class="flex_column" htmlFor="year">År:</label>
            <select id="year" name="year" onChange={(e) => setYear(e.target.value)}>
              { years.map((year) => (
                <option value={year}>{year}</option>
              ))}
            </select>
          </div>
          <div class="flex_column">
            <label  htmlFor="week">Vecka:</label>
            <select id="week" name="week" onChange={(e) => setWeek(e.target.value)}>
              { weeks.map((week) => (
                <option value={week}>{week}</option>
              ))}
            </select>
          </div>
        </div>
        <input className="btn" type={"submit"} value={"Submit"} />
      </form>
    )
}

export default ResultForm;