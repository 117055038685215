import React, {useState,useEffect} from 'react'
import { getAllUsers } from './Firebase';

function UserManagementForm () {
    const [users, setUsers] = useState(null);

    useEffect(() => {
        getAllUsers().then((result) => {
            setUsers(result);
        });
    }, []);


    return(
        <div>
        </div>
    )
}

export default UserManagementForm