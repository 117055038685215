// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { collection, doc, setDoc, getDocs, query, where, deleteDoc, updateDoc } from "firebase/firestore";
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyD-gM5baZFUQ5eby9NsifobUwbPnumU708",
    authDomain: "systemsteget.firebaseapp.com",
    projectId: "systemsteget",
    storageBucket: "systemsteget.appspot.com",
    messagingSenderId: "128946623807",
    appId: "1:128946623807:web:8dd61e2b58e0f92d776f8a",
    measurementId: "G-M2291D2QW2"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const functions = getFunctions(app);
export const auth = getAuth(app);

export const getAllUsers = async () => {
    const listUsers = httpsCallable(functions,'listUsers');

    return listUsers()
      .then((result) => {
        // console.log(result);
        return result.data;
      })
      .catch((error) => {
        // console.error('Error retrieving user list:', error);
      });
};

export const setUserRole = async (uid, role) => {
    //call the cloud function setCustomClaims
    const setCustomClaims = httpsCallable(functions, 'setCustomClaims');
    try {
        const result = await setCustomClaims({ uid, role });
        return result;
    } catch (error) {
        // console.log(error);
    }

};

export const getUserRole = async () => {
    const user = auth.currentUser;
    // console.log("getuserrolecurrentuser: " + auth.currentUser)
    if (!user) {
        return null;
    }
    const idTokenResult = await user.getIdTokenResult();
    // console.log("getuserrole: " + idTokenResult.claims.role + " typeof: " + typeof idTokenResult.claims.role)
    return idTokenResult.claims.role;
};



export const registerUser = (email, password) => {
    createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            // Signed in 
            const user = userCredential.user;
            // ...
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            // ..
        });
}

export const loginUser = async (email, password) => {
    signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            const user = userCredential.user;
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
        });
}

export const logoutUser = () => {
    auth.signOut();
}

const db = getFirestore(app);

export const addUser = async (name) => {
    const docRef = doc(collection(db, "User"));
    await setDoc(docRef, {
        name: name
    });
}

export const updateUser = async (id, newName) => {
    const docRef = doc(db, "User", id);
    await updateDoc(docRef, {
        name: newName
    });
}

export const deleteUser = async (id) => {
    const docRef = doc(db, "User", id);
    await deleteDoc(docRef);
}

export const addResult = async (user, steps, year, week) => {
    const docRef = doc(collection(db, "Step"));
    await setDoc(docRef, {
        steps: Number(steps),
        user: user,
        week: Number(week),
        year: Number(year)
    });
}

export const updateResult = async (user, year, week) => {
    const docRef = doc(db, "Step");
    await updateDoc(docRef, {
        user: user,
        week: Number(week),
        year: Number(year)
    });
}

export const deleteResult = async (user, year, week) => {
    {/*
    const docRef = doc(collection(db, "Step"));
    await deleteDoc(docRef, {
        user: user,
        year: Number(year),
        week: Number(week)
    });



    var step_query = db.collection('Step').where('user','==',user, 'AND', 'year', '==', year, 'AND','week','==',week);
    step_query.get().then(function(querySnapshot) {
    querySnapshot.forEach(function(doc) {
    doc.ref.delete();
  });

  */}
  

  const q3 = query(collection(db, "Step"), where("user", "==", user),where("week", "==", week), where("year", "==", year))
    
    const querySnapshot = await getDocs(q3);
    querySnapshot.forEach(function(doc) {
        deleteDoc(doc.ref);
    });

}

export const getUsers = async () => {
    const users = await getDocs(collection(db, "User"));
    var userList = [];
    users.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        userList = [{ id: doc.id, name: doc.data().name }, ...userList];
    });
    return userList;
}

export const getUsersWithoutSteps = async () => {
    const users = await getDocs(collection(db, "User"));
    var userList = [];
    users.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        const stepsExist = StepsExist(doc.id)
        if(!StepsExist(doc.id))
        {
            userList = [{ id: doc.id, name: doc.data().name }, ...userList];
        }
        
    });
    return userList;
}

export const StepsExist = async (id) => {
    const q = query(collection(db, "Step"), where("user", "==", id))
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots    
        if(doc.data())
        {
            return true;
        }      
    });
    return false;
}

export const getSteps = async (year) => {
    const q = query(collection(db, "Step"), where("year", "==", year))
    var stepList = [];
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        stepList = [doc.data(), ...stepList];
    });
    return stepList;
}


export const getStepsSpecificWeek = async (week, year) => {
    const q3 = query(collection(db, "Step"), where("year", "==", year),where("week", "==", week))
    var stepList = [];
    const querySnapshot = await getDocs(q3);
    querySnapshot.forEach((doc) => {
        stepList = [doc.data(), ...stepList];
    });
    return stepList;
}


export const getResults = async (year) => {
    // console.log("getspecific: " + getStepsSpecificWeek(2, 2022));
    // var specificSteps = await getStepsSpecificWeek(1, 2022);
    // specificSteps.forEach(step => {
    //     console.log("specific: " + step.user + " " + step.week + " " + step.steps)
    // })
    


    var users = await getUsers();
    var steps = await getSteps(year);

    var stepsWeek1 = await getStepsSpecificWeek(1, year);
    var stepsWeek2 = await getStepsSpecificWeek(2, year);

    var results = [];

    
    users.forEach(user => {
        var week1 = 0;
        var week2 = 0;

        stepsWeek1.forEach(step => {
            // console.log("week1: " + user + " " + step)
            if (step.user == user.id) {
                week1 += step.steps;
                stepsWeek1.splice(stepsWeek1.indexOf(step), 1);
            }
        })
        stepsWeek2.forEach(step => {
            if (step.user == user.id) {
                week2 += step.steps;
                stepsWeek2.splice(stepsWeek2.indexOf(step), 1);
            }
        })

        var totalSteps = week1 + week2;
        // var totalSteps = 0;
        // steps.forEach(step => {
        //     console.log(step);
        //     if (step.user == user.id) {
        //         totalSteps += step.steps;
        //         steps.splice(steps.indexOf(step), 1);
        //     }
        //});
        if (totalSteps > 0)
            results = [{userID: user.id, name: user.name, totalSteps: totalSteps, stepsWeek1: week1, stepsWeek2: week2 }, ...results];
    });
    //sort results by steps
    results.sort((a, b) => (a.totalSteps > b.totalSteps) ? -1 : 1);
    return results;
}