import './App.css';
import React, { useState, useEffect, useRef } from 'react';
import Leaderboard from './components/Leaderboard';
import Admin from './components/Admin';
import Home from './components/Home';
import Regler from './components/Regler';
import Information from './components/Information';
import { BrowserRouter as Router, Outlet, Routes, Route, Link } from 'react-router-dom';
import Navbar from './components/Navbar';
import NavItem from './components/NavItem';
import DropdownMenu from './components/DropdownMenu';
import Contact from './components/Contact';
import DropdownItem from './components/DropdownItem';
import Login from './components/Login';
import NoPage from './components/NoPage';
import { useAuth } from './components/UserContext';
import { getUserRole } from './components/Firebase';


function App() {
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(false)

  let menuRef = useRef()
  let menuItemRef = useRef()
  
  useEffect(() => {
     let handler = (e) =>{
      if(!menuRef.current.contains(e.target)){
        setOpenDropdown(false);
      }
     }
     document.addEventListener("mousedown", handler);

     return() =>{
      document.removeEventListener("mousedown", handler)
     }
  });

  useEffect(() => {
    const checkAdmin = async () => {
      const role = await getUserRole();
      if (role == "admin") {
        setIsAdmin(true);
        setIsLoading(false);
      }
      else {
        setIsAdmin(false);
        setIsLoading(false);
      }
    }
    checkAdmin();
  }, [user]);

  return (
    <div className="App">
      <Router>
        {/*Navbar includes navigation buttons in desktopmode*/}
        <Navbar  isAdmin={isAdmin}>
          {/*NavItem is hamburger menu in mobile and tabmode*/}
          <div ref={menuRef}>
          <NavItem onClick={() => setOpenDropdown(!openDropdown)}  icon={<img src='https://upload.wikimedia.org/wikipedia/commons/5/59/Hamburger_icon_white.svg'/>}>
            {openDropdown ?
              <DropdownMenu>
                <DropdownItem href="/leaderboard" text="Leaderboard" onClick={() => setOpenDropdown(!openDropdown)}/>
                <DropdownItem href="/regler" text="Regler" onClick={() => setOpenDropdown(!openDropdown)}/>
                {/* <DropdownItem href="/information" text="Information" onClick={() => setOpenDropdown(!openDropdown)}/> */}
                {/*If user is loged in as admin, an admin button appears*/}
                {isAdmin ? <DropdownItem href="/admin" text="Admin" onClick={() => setOpenDropdown(!openDropdown)}/> : <></>}
                {/* {user ? <DropdownItem href="/login" text="Logout" onClick={() => setOpenDropdown(!openDropdown)}/> : <DropdownItem href="/login" text="Login" onClick={() => setOpenDropdown(!openDropdown)}/>} */ <></>}

                <DropdownItem href="/contact" text="Kontakt" onClick={() => setOpenDropdown(!openDropdown)}/>
            </DropdownMenu>
            :
            <></>
            }
          
          </NavItem>
          </div>
        </Navbar>
        <div className="totalFrame">
        </div>
        <div className="mainFrame">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/regler" element={<Regler />} />
            {/* <Route path="/information" element={<Information />} /> */}
            <Route path="/admin" element={<Admin user={user}/>} />
            <Route path="/leaderboard" element={<Leaderboard user={user} />} />
            <Route path="/login" element={<Login />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="*" element={<NoPage />} />
          </Routes>
        </div>
        <Outlet></Outlet>
      </Router>

    </div>
  );
}


export default App;
